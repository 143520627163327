
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function CustomFacetDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title-container' }, _createElement('div', { 'className': 'dialog-title' }, [_createElement('svg', {
            'width': '26',
            'height': '22',
            'viewBox': '0 0 26 22',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '1710'
        }, _createElement('path', {
            'd': 'M11.375 1.57143V1.17857C11.375 0.530357 10.8266 0 10.1562 0H6.09375C5.42344 0 4.875 0.530357 4.875 1.17857V1.57143H0V4.71429H4.875V5.10714C4.875 5.75536 5.42344 6.28571 6.09375 6.28571H10.1562C10.8266 6.28571 11.375 5.75536 11.375 5.10714V4.71429H26V1.57143H11.375ZM6.5 4.71429V1.57143H9.75V4.71429H6.5ZM21.125 9.03571C21.125 8.3875 20.5766 7.85714 19.9062 7.85714H15.8438C15.1734 7.85714 14.625 8.3875 14.625 9.03571V9.42857H0V12.5714H14.625V12.9643C14.625 13.6125 15.1734 14.1429 15.8438 14.1429H19.9062C20.5766 14.1429 21.125 13.6125 21.125 12.9643V12.5714H26V9.42857H21.125V9.03571ZM16.25 12.5714V9.42857H19.5V12.5714H16.25ZM11.375 16.8929C11.375 16.2446 10.8266 15.7143 10.1562 15.7143H6.09375C5.42344 15.7143 4.875 16.2446 4.875 16.8929V17.2857H0V20.4286H4.875V20.8214C4.875 21.4696 5.42344 22 6.09375 22H10.1562C10.8266 22 11.375 21.4696 11.375 20.8214V20.4286H26V17.2857H11.375V16.8929ZM6.5 20.4286V17.2857H9.75V20.4286H6.5Z',
            'fill': '#D9D9D9'
        }))], _createElement('span', {}, 'filter by'))), _createElement('div', {
        'className': 'buttons-container',
        'data-cm-role': 'close'
    }, [_createElement('svg', {
            'width': '10',
            'height': '10',
            'viewBox': '0 0 10 10',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '13890'
        }, _createElement('path', {
            'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
            'fill': '#FD4A3F'
        }))])), _createElement('div', { 'className': 'dialog-body' }, [this.CustomFacetDialogFacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel cm_CustomFacetDialogFacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('button', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'aria-label': `Choose to discard the ${ this.value }`
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '412' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '538' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '638' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7730'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('button', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'aria-label': `Remove ${ this.field }: ${ this.value }`
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '3140'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'button',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'aria-label': `Choose to discard the ${ this.value }`
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '438' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '5420'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn button button--inverted cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('button', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'role': this.IsMultiselect ? 'checkbox' : 'radio',
                                                'aria-checked': this.isSelected,
                                                'aria-label': this.value,
                                                'tabIndex': '0'
                                            }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'tabIndex': '-1',
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('button', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'role': this.IsMultiselect ? 'checkbox' : 'radio',
                                                'aria-checked': this.isSelected,
                                                'tabIndex': '0'
                                            }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'tabIndex': '-1',
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeInchMm4() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '13242'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--inverted',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                    _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0',
                                        'key': '561'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '2380'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '5590'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                                    _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'key': '563'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1073'
                                    }, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '11591'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (!this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        {
                                            'role': this.IsMultiselect ? 'checkbox' : 'radiogroup',
                                            'className': 'cmRepeater_ShowAlwaysValues'
                                        },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        {
                                            'role': this.IsMultiselect ? 'checkbox' : 'radiogroup',
                                            'className': 'cmRepeater_Values'
                                        },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('span', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3406'
                                    }, _createElement('button', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0',
                                        'role': 'button',
                                        'aria-expanded': !this.moreValuesToShow,
                                        'aria-label': this.moreValuesToShow ? `Show more ${ this.name }` : `Show less ${ this.name }`
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3902'
                                    }, [this.select(function () {
                                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                                            }
                                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                                return [
                                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                        'value': '',
                                                        'key': 'null-option'
                                                    }, '\n          ', this.title, '\n        ') : null,
                                                    this.loading ? _createElement('option', {
                                                        'key': 'loading-option',
                                                        'disabled': true
                                                    }, '...loading...') : null,
                                                    isPopularField ? [
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '15371'
                                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                                        _createElement('option', {
                                                            'className': 'cm_option_title',
                                                            'disabled': true,
                                                            'key': '15374'
                                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                                    ] : null,
                                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                                ];
                                            }
                                            function scopeShowSelectedValues4() {
                                                var showSelectedValues = this.selectedEntries.length;
                                                return _createElement('option', {
                                                    'key': '_current',
                                                    'value': '_current'
                                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                                            }
                                            function repeatEntry5(entry, entryIndex) {
                                                return _createElement('div', {
                                                    'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, _createElement('div', {
                                                    'className': 'cm_dropdown_value',
                                                    'role': 'option',
                                                    'aria-selected': entry.selected
                                                }, entry.value));
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'aria-label': `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'tabIndex': this.disabled ? '-1' : '0',
                                                'role': 'combobox',
                                                'aria-haspopup': 'listbox',
                                                'aria-autocomplete': 'none',
                                                'aria-controls': `${ this.title }-combobox`,
                                                'aria-selected': this.selectedEntries.length > 0
                                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null), [_createElement('svg', {
                                                    'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                                    'width': '11',
                                                    'height': '7',
                                                    'viewBox': '0 0 11 7',
                                                    'fill': 'none',
                                                    'xmlns': 'http://www.w3.org/2000/svg',
                                                    'aria-hidden': 'true',
                                                    'key': '28920'
                                                }, _createElement('path', {
                                                    'fillRule': 'evenodd',
                                                    'clipRule': 'evenodd',
                                                    'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                                    'fill': '#1A1717'
                                                }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'role': 'listbox',
                                                    'key': '3384'
                                                },
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '10306'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '103901' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '103903'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '106541'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--inverted',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '15004'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null)
                                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '18152'
                                }, _createElement('div', {
                                    'className': 'facettitle cm_facet-toggle',
                                    'role': 'group',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'role': 'checkbox',
                                    'aria-label': this.values[0].value,
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet,
                                    'tabIndex': '0'
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.CustomFacetDialogVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                        }
                        function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                            var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                            var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                            var isPopularField = popularEntries.length && unpopularEntries.length;
                            return [
                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                    'value': '',
                                    'key': 'null-option'
                                }, '\n          ', this.title, '\n        ') : null,
                                this.loading ? _createElement('option', {
                                    'key': 'loading-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                isPopularField ? [
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '15141'
                                    }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                    _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                    _createElement('option', {
                                        'className': 'cm_option_title',
                                        'disabled': true,
                                        'key': '15144'
                                    }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                ] : null,
                                _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                            ];
                        }
                        function scopeShowSelectedValues4() {
                            var showSelectedValues = this.selectedEntries.length;
                            return _createElement('option', {
                                'key': '_current',
                                'value': '_current'
                            }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                        }
                        function repeatEntry5(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, _createElement('div', {
                                'className': 'cm_dropdown_value',
                                'role': 'option',
                                'aria-selected': entry.selected
                            }, entry.value));
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'aria-label': `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'tabIndex': this.disabled ? '-1' : '0',
                            'role': 'combobox',
                            'aria-haspopup': 'listbox',
                            'aria-autocomplete': 'none',
                            'aria-controls': `${ this.title }-combobox`,
                            'aria-selected': this.selectedEntries.length > 0
                        }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null), [_createElement('svg', {
                                'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                'width': '11',
                                'height': '7',
                                'viewBox': '0 0 11 7',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'aria-hidden': 'true',
                                'key': '28690'
                            }, _createElement('path', {
                                'fillRule': 'evenodd',
                                'clipRule': 'evenodd',
                                'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                'fill': '#1A1717'
                            }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'role': 'listbox',
                                'key': '3361'
                            },
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            function onKeyDown2(e) {
                {
                    if (e.type === 'keydown' && e.key === 'Enter') {
                        this.selectVehicle();
                    }
                }
            }
            function onKeyDown3(e) {
                {
                    if (e.type === 'keydown' && e.key === 'Enter') {
                        this.discardVehicle();
                    }
                }
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_CustomFacetDialogVehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '91'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                'onClick': this.selectVehicle,
                'onKeyDown': onKeyDown2.bind(this),
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    Add vehicle\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--inverted',
                'onClick': this.discardVehicle,
                'onKeyDown': onKeyDown3.bind(this),
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    Clear vehicle\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '7509'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button button',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change Vehicle\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button button button--inverted',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear Selection\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:CustomFacetDialogFacetPanel","cm:facetToggle","cm:CustomFacetDialogVehicleWidget"]